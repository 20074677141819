import React from 'react';

import useClientSettings from 'web/hooks/useClientSettings';
import {humanPriceFromCents} from 'infra/formatters/money';

import OrderPanel from '../../components/order_panel';

const OrderSummary = ({order, user}) => {
  const clientSettings = useClientSettings();

  return (
    <OrderPanel
      orderId={order.id}
      isEditable={order.isEditable}
      isLinkable="true"
      orderCutoff={order.orderCutoff}
      startAt={order.deliveryWindow.startAt}
      endAt={order.deliveryWindow.endAt}
      hasSubscribedItems={order.hasSubscribedItems}
      additionalPricingDetails={order.additionalPricingDetails}
      user={user}
      tzid={clientSettings.tzid}
      deliveryStatus={order.deliveryStatus}
    >
      <div className="orders-page__order-summary layout-row">
        <div className="layout-cell--expanding">
          {order.isGift ? (
            <div className="orders-page__gift-recipient">
              <label>gift recipient</label>
              <a href={`/account/orders/${order.id}`} className="orders-page__summary-content">
                {order.deliveryDetails.giftRecipientName}
              </a>
            </div>
          ) : (
            <div className="orders-page__address">
              <label>address</label>
              <a href={`/account/orders/${order.id}`} className="orders-page__summary-content">
                {order.deliveryDetails.address}
              </a>
            </div>
          )}
          <div className="orders-page__items">
            <label>items</label>
            <a href={`/account/orders/${order.id}`} className="orders-page__summary-content">
              {order.itemCount} {order.itemCount > 1 ? 'Products' : 'Product'}
            </a>
          </div>
          <div className="orders-page__total">
            <label>total</label>
            <a href={`/account/orders/${order.id}`} className="orders-page__summary-content">
              {humanPriceFromCents(order.totals.stripeTotal)}
            </a>
          </div>
        </div>
        <div className="orders-page__details layout-cell">
          <label />
          <a className="orders-page__order-details-link" href={`/account/orders/${order.id}`}>
            <span> Order Details </span>
            <i className="orders-page__order-details-link-chevron icon icon-chevron right" />
          </a>
        </div>
      </div>
    </OrderPanel>
  );
};

export default OrderSummary;
