import {Helmet} from 'react-helmet-async';
import {connect} from 'react-redux';
import React, {Component} from 'react';

import Alert from 'web/components/alert';
import MarketLayout from 'web/components/market_layout';

import OrderSummary from './components/order_summary';

class OrdersPage extends Component {
  static reducer(state, action) {
    return MarketLayout.reducer(state, action);
  }

  renderOrdersSection(sectionName, orders) {
    return (
      <div className="orders-page__section">
        <h1 className="orders-page__section-title">{sectionName}</h1>
        {orders.map((order) => (
          <OrderSummary key={order.id} order={order} user={this.props.user} />
        ))}
      </div>
    );
  }

  renderEmptyOrdersPage() {
    return (
      <div className="orders-page__empty-section">
        <h1 className="orders-page__section-title">Your Orders</h1>
        <div className="orders-page__empty-content">You haven{"'"}t placed any orders yet.</div>
        <a href={`/`} className="orders-page__shop-button">
          shop the market
        </a>
      </div>
    );
  }

  render() {
    return (
      <MarketLayout
        categories={this.props.categories}
        foodhub={this.props.foodhub}
        user={this.props.user}
        disableUpcomingOrdersBanner
      >
        <Helmet>
          <title>Your Orders | Good Eggs</title>
        </Helmet>

        <div className="orders-page">
          {this.props.formattedMessage ? (
            <div className="orders-page__message">
              <Alert
                type={this.props.formattedMessage.level}
                heading={this.props.formattedMessage.customerMessage}
              >
                {this.props.formattedMessage.messageDetails}
              </Alert>
            </div>
          ) : null}
          {this.props.populatedUpcomingOrders.length === 0 &&
          this.props.populatedPastOrders.length === 0
            ? this.renderEmptyOrdersPage()
            : null}
          {this.props.populatedUpcomingOrders.length > 0
            ? this.renderOrdersSection('Upcoming Orders', this.props.populatedUpcomingOrders)
            : null}
          {this.props.populatedPastOrders.length > 0
            ? this.renderOrdersSection('Past Orders', this.props.populatedPastOrders)
            : null}
        </div>
      </MarketLayout>
    );
  }
}

OrdersPage.pageName = 'Your Orders';

export default connect((state) => state)(OrdersPage);
